<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-10-21 14:50:29
 * @LastEditors: ------
 * @LastEditTime: 2021-11-03 09:49:35
-->
<template>
  <div id="con">
    <div class="bcImg">
      <img src="../../assets/c317e11633cfcf6f631600a90adaa6f.jpg" />
      <div class="input">
        <div class="name">
          <label>姓名</label>
          <br />
          <input type="text" placeholder="请输入您的姓名" v-model="info.name" />
        </div>
        <div class="phone">
          <label>电话</label>
          <br />
          <input
            type="text"
            placeholder="请输入您的电话"
            v-model="info.phone"
          />
        </div>
        <button @click="nowBtn()">立即咨询</button>
      </div>
      <a href="tel:400-180-1219"><button class="call">拨打电话</button></a>
    </div>
  </div>
</template>

<script>
import { postAddConsult } from "../../utils/request";
export default {
  name: "",
  data() {
    return {
      info: {
        name: "",
        phone: "",
      },
    };
  },
  methods: {
    nowBtn() {
      if (this.info.name == "" || this.info.phone == "") {
        this.$dialog
          .alert({
            message: "姓名或电话不能为空！",
            // theme: "round-button",
          })
          .then(() => {
            // on close
          });
      } else {
        postAddConsult(this.info).then((res) => {
          if (res.data.success) {
            this.$dialog.alert({
              message: "预约成功！",
              theme: "round-button",
            });
          } else {
            alert("预约失败！" + res.data.message);
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
#con {
  width: 100%;
}
.bcImg {
  width: 100%;
  text-align: center;
  margin: auto;
  position: relative;
  img {
    width: 100%;
  }
}
.input {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 340px;
  margin-top: 200px;
  text-align: center;
  line-height: 1;
  .phone,
  .name {
    text-align: left;
    padding-left: 24px;
    label {
      font-size: 14px;
      font-weight: 500;
    }
    input {
      width: 278px;
      height: 38px;
      opacity: 0.6;
      font-size: 13px;
      border-radius: 4px;
      padding-left: 10px;
      margin-bottom: 16px;
      margin-top: 6px;
      border: 1px solid #999999;
    }
  }
  button {
    border: none;
    width: 286px;
    height: 42px;
    margin-top: 20px;
    background: linear-gradient(0deg, #84f5de 0%, #56e8f2 50%, #4cc5f8 100%);
    border-radius: 20px;
    color: #ffffff;
    font-size: 17px;
    cursor: pointer;
    &:active {
      box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.116);
      background: linear-gradient(0deg, #7ff5dd 0%, #53edf8 50%, #45c2f8 100%);
    }
  }
}
.call {
  border: none;
  position: absolute;
  bottom: 90px;
  right: 0;
  left: 0;
  margin: auto;
  width: 289px;
  height: 42px;
  background: #f6ac19;
  border-radius: 20px;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  line-height: 42px;
  &:active {
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.116);
  }
}
</style>
